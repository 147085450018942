window.computePassStrength = function (data){
    let score = 0;
    if (hasOneBigLetter(data)) {
        score++;
    }
    if (hasOneSmallLetter(data)) {
        score++;
    }
    if (hasOneNumber(data)) {
        score++;
    }
    if (hasOneSpecialChar(data)) {
        score++;
    }
    if (isLongEnough(data)) {
        score++;
    }

    return '' + score-1;
};

export function hasOneBigLetter(data){
    return (/[A-Z]/.test(data));
};

export function  hasOneSmallLetter(data){
    return (/[a-z]/.test(data));
};

export function hasOneNumber(data){
    return (/[0-9]/.test(data));
};

export function hasOneSpecialChar(data){
    let format = /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/;
    return (format.test(data));
};

export function isLongEnough(data){
    if(data.length >= 8) {
        return true;
    }
    return false;
};
